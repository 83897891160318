import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo'
import Layout from '../components/layout'
import DefaultCollection from '../components/defaultCollection'
import PageNum from '../components/pageNum'

const NewsPage = ({ data: { news, coverImg }, pageContext }) => {
  const slug = pageContext.slug
  const title = pageContext.title
  return (
    <Layout>
      <Seo title={slug} pageUrl={slug} />
      <DefaultCollection category={'news'} data={news} coverImg={coverImg} slug={slug} title={title} />
      <PageNum pageContext={pageContext} />
    </Layout>
  )
}
export default NewsPage

export const query = graphql`
  query newsListQuery($skip: Int!, $limit: Int!) {
    coverImg: contentfulPage(slug: { eq: "news-index" }) {
      featuredImage {
        gatsbyImageData
      }
    }
    news: allContentfulNews(limit: $limit, skip: $skip, sort: { fields: publishDate, order: DESC }) {
      edges {
        node {
          id
          slug
          title
          publishDate(formatString: "YYYY.MM.DD")
          featuredImage {
            gatsbyImageData
          }
          createdAt(formatString: "YYYY.MM.DD")
          content {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 100, truncate: true)
            }
          }
        }
      }
    }
  }
`
